'use client';

import { useIsUnderBreakpoint } from '@qb/frontend/hooks/mediaQueries';
import { BuyerDashboardDataResponse, SupplierDashboardDataResponse } from '@/shared/types/controllers/DashboardControllerTypes';
import { DesktopRecentOrdersTable } from './DesktopRecentOrdersTable';
import { MobileRecentOrdersTable } from './MobileRecentOrdersTable';
import { NoOrdersFound } from './NoOrdersFound';
type RecentOrdersTableProps = {
  orders: BuyerDashboardDataResponse['orders'] | SupplierDashboardDataResponse['orders'];
  orderCount: number;
};
export const RecentOrdersTable = ({
  orders,
  orderCount
}: RecentOrdersTableProps) => {
  const isUnderBreakpointSm = useIsUnderBreakpoint('sm');
  if (orders.length === 0) {
    return <NoOrdersFound />;
  }
  return isUnderBreakpointSm ? <MobileRecentOrdersTable orders={orders} orderCount={orderCount} /> : <DesktopRecentOrdersTable orders={orders} orderCount={orderCount} />;
};