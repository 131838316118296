import Image from 'next/image';
import Link from 'next/link';
import { Box, Link as MuiLink, Paper, Typography } from '@mui/material';
import { BuyerDashboardDataResponse, SupplierDashboardDataResponse } from '@/shared/types/controllers/DashboardControllerTypes';
import { getLabelForStatusText } from '@/src/components/Quote/QuoteStatus';
import { isSupplierQuoteData } from '../../utils/isSupplierQuoteData';
import { getImageSrc, getPartNumberToDisplay } from '../../utils/recentQuotesDataParsers';
import { RecentQuotesActionBtns } from './RecentQuotesActionBtns';
import { RecentQuotesHeading } from './RecentQuotesHeading';
type MobileRecentQuotesTableProps = {
  quotes: BuyerDashboardDataResponse['quotes'] | SupplierDashboardDataResponse['quotes'];
  quoteCount: number;
};
export const MobileRecentQuotesTable = ({
  quotes,
  quoteCount
}: MobileRecentQuotesTableProps) => {
  return <>
      <RecentQuotesHeading quoteCount={quoteCount} isSupplierView={isSupplierQuoteData(quotes[0])} data-sentry-element="RecentQuotesHeading" data-sentry-source-file="MobileRecentQuotesTable.tsx" />
      {quotes.map(quote => {
      return <Paper key={quote.id} sx={{
        p: 2,
        my: 1,
        border: 'none',
        display: 'flex',
        gap: 1
      }}>
            <Image src={getImageSrc(quote.partRequests)} width={40} height={40} alt="Part image" />
            <Box sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 1
        }}>
              <MuiLink href={`/quote/${quote.id}/display`}>
                {getPartNumberToDisplay(quote.partRequests)}
              </MuiLink>
              <Typography variant="bodyMedium">
                {isSupplierQuoteData(quote) ? `CUSTOMER: ${quote.buyer?.name || quote.contact?.companyName || ''}` : `SUPPLIER: ${quote.supplier?.name || ''}`}
              </Typography>
              <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2
          }}>
                <Link href={`/quote/${quote.id}/display`}>
                  {getLabelForStatusText(quote.statusText)}
                </Link>
                <RecentQuotesActionBtns quoteID={quote.id} quoteWatchObject={quote.watchObject} />
              </Box>
            </Box>
          </Paper>;
    })}
    </>;
};