'use client';

import { useIsUnderBreakpoint } from '@qb/frontend/hooks/mediaQueries';
import { BuyerDashboardDataResponse, SupplierDashboardDataResponse } from '@/shared/types/controllers/DashboardControllerTypes';
import { DesktopRecentQuotesTable } from './DesktopRecentQuotesTable';
import { MobileRecentQuotesTable } from './MobileRecentQuotesTable';
import { NoQuotesFound } from './NoQuotesFound';
export type RecentQuotesTableProps = {
  quotes: BuyerDashboardDataResponse['quotes'] | SupplierDashboardDataResponse['quotes'];
  quoteCount: number;
};
export const RecentQuotesTable = ({
  quotes,
  quoteCount
}: RecentQuotesTableProps) => {
  const isUnderBreakpointSm = useIsUnderBreakpoint('sm');
  if (quotes.length === 0) {
    return <NoQuotesFound />;
  }
  return isUnderBreakpointSm ? <MobileRecentQuotesTable quotes={quotes} quoteCount={quoteCount} /> : <DesktopRecentQuotesTable quotes={quotes} quoteCount={quoteCount} />;
};